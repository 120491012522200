import React, { Suspense, lazy } from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import Header from '../components/header'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-image-lightbox/style.css'
import './App.css'

const Home = lazy(() => import('./Home/Home'))
const Gallery = lazy(() => import('./Gallery/Gallery'))

export default function App () {
  return (
    <Router>
      <Header />
      <Suspense fallback={'...'}>
        <Route exact path="/" component={Home} />
        <Route exact path="/gallery/:slug" component={Gallery} />
      </Suspense>
    </Router>
  )
}
