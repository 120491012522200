import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

export default function Header () {
  return useMemo(() => (
    <header>
      <div className="row ng-scope">
        <div className="col-xs-12 col-sm-9">
          <Link className="margintitle" to="/"><h1>Blanca Lawliet</h1></Link>
        </div>
      </div>
    </header>
  ), [])
}
